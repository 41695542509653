import React, { useCallback, useLayoutEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";
import { authenticate } from "../services/webapi/auth";
import { useAsyncCancelableCallback } from "../utils/asyncHooks";

import { useAccessBoundary } from "../components/app/AccessBoundary";
import { useErrorBoundary } from "../components/app/ErrorBoundary";

import logoImagePath from "../components/app/logo.png";

/**
 * Pantalla a mostrar si se introduce una url inválida
 */
const Login: React.FC = () => {

  const useStyles = makeStyles(theme => ({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      margin: 10,
    },
    button: {
      marginTop: theme.spacing(3),
    },
    form: {
      marginTop: theme.spacing(1),
    },
    header: {
      alignItems: "center",
      display: "flex",
      marginTop: theme.spacing(2),
    },
    image: {
      width: "170px",
    },
    login: {
      backgroundImage: 'url("/fondo-login.jpg")',
      backgroundSize: "cover",
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      marginTop: "64px",
      maxWidth: "444px",
      padding: theme.spacing(2),
    },
    root: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: "1.25rem",
    },
  }));

  useLayoutEffect(() => {
    document.body.classList.add(classes.login);

    return () => document.body.classList.remove(classes.login);
  });

  const classes = useStyles();
  const { login } = useAccessBoundary();
  const { setError } = useErrorBoundary();

  const [username, setUsername] = useState("");
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const loginBtnDisabled = password.length === 0 || username.length === 0 || clientId.length === 0;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeInputHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.currentTarget;

      if (name === "user") {
        setUsername(value);
      } else if (name === "password") {
        setPassword(value);
      } else if (name === "client") {
        setClientId(value);
      }
    },
    []);

  const decoration: TextFieldProps["InputProps"] = ({
    endAdornment: (
      <>
        <InputAdornment position="end">
          <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      </>
    ),
  });

  const loginHandler = useAsyncCancelableCallback(
    async () => await authenticate(clientId, username, password),
    success => success
      ? login()
      : setError({ message: "No se ha podido autenticar. Usuario o credenciales incorrectas." }),
    reason => setError({
      message: "Se ha producido un error al autenticar.",
      reason,
    }), [clientId, password, username]);

  /** Handler para tratar el envío del formulario. */
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    loginHandler();

  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <img
          alt="Viajes InterRías"
          className={classes.image}
          src={logoImagePath}
        />
        <div className={classes.header}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <span className={classes.title}>Acceso facturación electrónica</span>
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="standard"
            margin="normal"
            required={true}
            fullWidth={true}
            id="client"
            label="Proveedor"
            name="client"
            onChange={changeInputHandler}
            autoComplete="client"
            autoFocus={true}
          />
          <TextField
            variant="standard"
            margin="normal"
            required={true}
            fullWidth={true}
            id="user"
            label="Usuario"
            name="user"
            onChange={changeInputHandler}
            autoComplete="user"
          />
          <TextField
            variant="standard"
            margin="normal"
            required={true}
            fullWidth={true}
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={changeInputHandler}
            InputProps={decoration}
          />
          <Button
            className={classes.button}
            type="submit"
            fullWidth={true}
            variant="contained"
            disabled={loginBtnDisabled}
            color="primary"
          >
            Acceder
          </Button>
        </form>
      </Paper>
    </div>
  );

};

export default Login;
