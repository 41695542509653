
import { removeAuthorizationToken } from "./client";
import { ApiError, Unauthorized } from "./client/types";

import client from "./client";

/**
 * Autentica al usuario obteniendo un token de autenticación.
 *
 * @param agency      el código de agencia
 * @param username    el nombre de login de usuario
 * @param password    la contraseña del usuario
 */
export let authenticate = async (agency: string, username: string, password: string): Promise<boolean> => {
  try {
    const auth = `${agency}#${username}`;

    await client.get(
      "auth",
      { auth: { password, username: auth } }
    );
  } catch (error) {

    if (error === Unauthorized || (error.type === ApiError && error.status === 401)) {
      /* Si en esta petición nos da 401 no es por error, es porque las credenciales
       * no son correctas. Si sucede en el login se devuelve null en vez de propagar
       * el error. */
      /* Consideramos también 403 en el if por si acaso, aunque debería ser 401. */
      return false;
    }

    throw error;
  }

  return true;
};

/**
 * Elimina la autenticación
 *
 */
export let logout = () => {
  removeAuthorizationToken();
};
