import React, { useCallback, useContext, useMemo, useState } from "react";

import { logout as logoutSession } from "../../services/webapi/auth";
import { get as getProfile } from "../../services/webapi/profiles";
import { useAsyncCancelableEffect as useAsyncEffect } from "../../utils/asyncHooks";
import Login from "../../views/Login";
import ErrorAlert from "../ui/ErrorAlert";
import LoadingOverlay from "../ui/LoadingOverlay";

/** Usuario */
export interface User {
  /** El teléfono móvil del usuario. */
  mobilePhoneNumber?: string;

  /** El nombre del usuario. */
  name?: string;

}

/** Contexto de AccessBoundary */
export interface AccessBoundaryContext {
  /** Usuario autenticado. */
  authUser?: User | null;

  /** Handler invocado cuando abrir cerrar sesión.  */
  login: () => void;

  /** Handler invocado cuando queremos cerrar sesión. */
  logout: () => void;
}

const AccessBoundaryContextInstance = React.createContext<AccessBoundaryContext>({
  login: () => null,
  logout: () => null,
});

/** Hook para dar acceso al contexto. */
export const useAccessBoundary = () => {
  const { authUser, login, logout } = useContext(AccessBoundaryContextInstance);

  return {
    authUser,
    login,
    logout,
  };
};

/**
 * Control para permitir el acceso únicamente si el usuario está autenticado.
 * Se controla validando el token de autentiación recibido.
 * En caso de estar autenticado, proveerá la información del usuario.
 */
const AccessBoundary: React.FC = props => {

  const [accessGranted, setAccessGranted] = useState<boolean>();
  const [authUser, setAuthUser] = useState<User>();
  const [apiError, setApiError] = useState(false);

  /* Actualización del usuario duando cambie el token de autenticación. */
  useAsyncEffect(
    async (): Promise<User | undefined> => {
      setAccessGranted(undefined);
      const profile = await getProfile();
      if (profile) {
        return {
          mobilePhoneNumber: profile.mobilePhoneNumber,
          name: profile.name || "",
        };
      } else {
        return undefined;
      }
    },
    user => {
      setAuthUser(user);
      setAccessGranted(user != null);
    },
    reason => {
      if (!reason || !(reason.status === 401 || reason.status === 403)) {
        setApiError(true);
      }
      setAuthUser(undefined);
      setAccessGranted(false);
    },
    []);

  const logout = useCallback(() => {
    setAuthUser(undefined);
    setAccessGranted(false);
    logoutSession();
  }, []);

  const login = useCallback(async () => {
    const profile = await getProfile();
    if (profile) {
      setAuthUser({
        mobilePhoneNumber: profile.mobilePhoneNumber,
        name: profile.name || "",
      });
      setAccessGranted(true);
    }
  }, []);

  /* Valor del contexto. */
  const contextValue = useMemo(
    () => ({
      authUser,
      login,
      logout,
    }),
    [authUser, login, logout]);

  /* Renderizado. */
  let content = null;
  switch (accessGranted) {
    case undefined:
      content = <LoadingOverlay visible={true} />;
      break;
    case true:
      content = props.children;
      break;
    default:
      content = !apiError
        ? <Login />
        : <ErrorAlert title="Error interno" message="No se ha podido llevar a cabo la autenticación." />;
  }

  return (
    <AccessBoundaryContextInstance.Provider value={contextValue}>
      {content}
    </AccessBoundaryContextInstance.Provider>
  );

};

export default AccessBoundary;
