import { Profile } from "../types";

import {
  maybe,
  randomName,
  randomString,
} from "./mockUtils";

export const get = (): Profile => ({
  id: randomString(),
  mobilePhoneNumber: maybe("999 999 999", 0.75),
  name: randomName(),
  providerCode: randomString(),
});
