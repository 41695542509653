import React, { useEffect, useState } from "react";

import { addRequestTracker, removeRequestTracker, RequestTracker } from "../../services/webapi/client";
import LoadingOverlay from "./LoadingOverlay";

/**
 * Capa de espera a mostrar mientras se realizan peticiones ajax a webapi.
 */
const WaitingLayer: React.FC = () => {

  /** Indica el número de peticiones activas. */
  const [active, setActive] = useState(0);

  useEffect(() => {
    const requestTracker: RequestTracker = status => {
      switch (status) {
        case "END":
          setActive(prevActive => Math.max(prevActive - 1, 0));
          break;
        case "START":
          setActive(prevActive => prevActive + 1);
          break;
      }
    };

    addRequestTracker(requestTracker);

    return () => removeRequestTracker(requestTracker);
  }, []);

  return (
    <LoadingOverlay visible={active > 0} />
  );

};

export default WaitingLayer;
