import { File, Service } from "../types";

const services: Service[] = [
  { id: 13776, name: "RESTAURANTE CARRIS ALMIRANTE" },
  { id: 16293, name: "CARRIS ALMIRANTE" },
  { id: 16413, name: "METROPOL BY CARRIS" },
  { id: 16417, name: "CARRIS CASA DE LA TROYA" },
  { id: 17174, name: "CARRIS MARINEDA" },
];

const names = [
  "Julio Segismundo",
  "Atilio Asunción",
  "Águeda Diana",
  "Marcelo Rut",
  "Amando Herminio",
  "Fermín Federico",
  "Flora Rosalva",
  "Francisca Emiliana",
  "Mireia Teófilo",
  "Lucio Raquel",
];

export const getServices = () => services;

export const maybe = <T>(value: T, ratio: number = 0.25) =>
  Math.random() <= ratio ? value : undefined;

export const oneOfArray = <T>(array: T[]): T => array[Number.parseInt(`${Math.random() * array.length}`, 10)];

export const oneOfEnum = <T>(enumObject: {}): T => {

  const enumKeys = Object.keys(enumObject)
    .filter(k => Number.isNaN(Number.parseInt(k, 10)));

  /* Esto es muy guarro. No usar en código real. Aquí porque es un mock.
   * Depende de cómo typescript genera internamente los enums. Lo mismo deja de
   * funcionar en futuras versiones. */
  return ((enumObject as unknown) as { [k: string]: T })[oneOfArray(enumKeys)];
};

export const randomAddress = () => {
  return oneOfArray([
    "Calle Ibarra y Cia s/n",
    "Polígono Industrial Aranguren 6",
    "Calle Gremi Passamaners 24 2º",
    "Calle Illa de Buda 55",
    "Barrio de Galindo s/n",
    "Avenida Manuel Rodriguez Ayuso, 170",
    "C/ Copérnico nº6",
    "Calle San Romualdo 13",
  ]);
};

export const randomDesc = () => {
  return Array(parseInt(`${Math.random() * 20}`, 10)).fill(1).map(() => randomString()).join(" ");
};

export const randomFile = (): File => ({
  name: randomString() + ".pdf",
  signature: maybe({
    issuer: "CN = Google Internet Authority G3,O = Google Trust Services,C = US",
    name: "Nombre titular certificado",
    signDate: "2019-01-01",
    subject: "CN = www.google.com,O = Google LLC,L = Mountain View,ST = California,C = US",
  }, 0.5),
  uploadDate: randomLocalDate(),
});

export const randomName = () => oneOfArray(names);

export const randomLocalDate = () => {
  const date = new Date();

  date.setDate(Math.random() * 29);
  date.setMonth(Math.random() * 12);

  return date.toISOString().split("T")[0];
};

export const randomLocata = () => {
  return "E" + Array(8).fill(1).map(() => (Math.random() * 9).toFixed(0)).join("");
};

export const randomPrice = () => Number.parseFloat((Math.random() * 15000).toFixed(2));

export const randomService = () => oneOfArray(services);

export const randomString = () => Math.random().toString(36).substring(2);
