/** Tipos de causas de error que se controlan. */
export const ApiError = "ApiError";
/* Axios los pasa todos a minúsculas. */
export const AuthTokenHeader = "auth-token";
export const HostUnreachable = "HostUnreachable";
export const ConnectionTimeout = "ConnectionTimeout";
export const Unauthorized = "Unauthorized";

/** Error producido durante la ejeución de la petición en servidor. */
export interface Failure {
  details?: ApiErrorDetails | undefined;
  status: number;
  type: typeof ApiError | typeof HostUnreachable | typeof ConnectionTimeout;
}

/** Detales del error recibidos en la respuesta del servidor. */
export interface ApiErrorDetails {
  exception?: string;
  message?: string;
  status?: number;
  timestamp?: string;
  trace?: string;
}
