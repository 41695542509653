import React from "react";

import clsx from "clsx";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/**
 * Estilos por defecto
 */
const styles = (theme: Theme) => createStyles({
  root: {
    borderBottomColor: theme.palette.text.primary,
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    color: theme.palette.text.primary,
    display: "block",
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  sizeExtraLarge: {
    fontSize: "1.5rem",
  },
  sizeLarge: {
    fontSize: "1.5rem",
  },
  sizeMedium: {
    fontSize: "1.25rem",
  },
  sizeSmall: {
    borderBottomWidth: 1,
    fontSize: "0.875rem",
  },
  variantPrimary: {
    borderBottomColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  variantPrimaryInverted: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "none",
    color: theme.palette.primary.contrastText,
  },
  variantSecondary: {
    borderBottomColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  variantSecondaryInverted: {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: "none",
    color: theme.palette.secondary.contrastText,
  },
});
const useStyles = makeStyles(styles, { name: "AppTitle" });

/**
 * Popiedades del componente
 */
export interface Props {
  /** Clase a aplicar a la raíz. */
  className?: string;

  /** Tamaño del título */
  size?:
  | "small"
  | "medium"
  | "large"
  | "extra-large"
  ;

  /** Variante de presentación. */
  variant?:
  | "primary"
  | "primary-inverted"
  | "secondary"
  | "secondary-inverted"
  | "default"
  ;
}

/**
 * Título de sección.
 */
const Title: React.FC<Props> = props => {
  const classes = useStyles();

  const { children, className: propClassName, size = "medium", variant = "default" } = props;

  const className = clsx("AppTitle",
    classes.root,
    {
      [classes.variantPrimary]: variant === "primary",
      [classes.variantPrimaryInverted]: variant === "primary-inverted",
      [classes.variantSecondary]: variant === "secondary",
      [classes.variantSecondaryInverted]: variant === "secondary-inverted",
      [classes.sizeExtraLarge]: size === "extra-large",
      [classes.sizeLarge]: size === "large",
      [classes.sizeMedium]: size === "medium",
      [classes.sizeSmall]: size === "small",
    },
    propClassName
  );

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Title;
