import { useCallback, useEffect, useRef } from "react";

type AsyncAction<T> = (...args: any[]) => Promise<T>;
type CleanUp = () => void;
type Effect<T> = (result: T) => any;
type ErrorHandler = (error: any) => void;
type MountRef = React.MutableRefObject<{
  isMounted: boolean;
}>;

/**
 * Hook para crear un callback que actualiza el estado como respuesta a una
 * acción asíncrona.
 *
 * La ejecución del efecto o del handler de error se cancela si el componente se
 * ha desmontado durante la ejecución de la acción asíncrona.
 *
 * @param asyncAction acción asíncrona
 * @param effect efecto a ejecutar tras la acción
 * @param onError función a ejecutar en caso de error
 * @param deps dependencias usadas en la acción y el efecto
 */
export function useAsyncCancelableCallback<T>(
  asyncAction: AsyncAction<T>,
  effect?: Effect<T> | null,
  onError?: ErrorHandler | null,
  deps: any[] = []) {

  const mountRef = useMountRef();

  return useCallback((...args: Parameters<typeof asyncAction>) => {

    asyncAction(...args).then(
      result => {
        if (mountRef.current.isMounted && effect) {
          effect(result);
        }
      },
      reason => {
        if (mountRef.current.isMounted && onError) {
          onError(reason);
        }
      }
    );
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps);
  // La línea anterior genera un warning ya que react-hooks/exhaustive-deps no
  // puede analizar las dependencias que llegan por parámetro.
  // Se ha comprobado que este caso es correcto y se desahabilita el check
  // para silenciar un warning que está revisado.
  //
  // ¡¡¡ OJO !!!
  // Si se modifica este código, hay que volvera activar eslint para verificar
  // que no se introduce ningún error y volver a revisar el warning antes de
  // desactivarlo.
}

/**
 * Hook para ejecutar un efecto después de una acción asíncrona. El efecto
 * recibirá como parámetro el resultado devuelto por la acción asíncrona.
 *
 * La ejecución del efecto o del handler de error se cancela si el componente se
 * ha desmontado durante la ejecución de la acción asíncrona.
 *
 * @param asyncAction acción asíncrona
 * @param effect efecto a ejecutar
 * @param onError función a ejecutar en caso de error
 * @param deps dependencias usadas en la acción y el efecto
 * @param cleanUp función clena-up que se propaga a useEffect
 */
export function useAsyncCancelableEffect<T>(
  asyncAction: AsyncAction<T>,
  effect?: Effect<T> | null,
  onError?: ErrorHandler | null,
  deps: any[] = [],
  cleanUp?: CleanUp) {

  useEffect(() => {

    let didCancel = false;

    asyncAction().then(
      result => {
        if (!didCancel && effect) {
          effect(result);
        }
      },
      reason => {
        if (!didCancel && onError) {
          onError(reason);
        }
      });

    return () => {
      didCancel = true;
      if (cleanUp) {
        cleanUp();
      }
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps);
  // La línea anterior genera un warning ya que react-hooks/exhaustive-deps no
  // puede analizar las dependencias que llegan por parámetro.
  // Se ha comprobado que este caso es correcto y se desahabilita el check
  // para silenciar un warning que está revisado.
  //
  // ¡¡¡ OJO !!!
  // Si se modifica este código, hay que volvera activar eslint para verificar
  // que no se introduce ningún error y volver a revisar el warning antes de
  // desactivarlo.
}

/**
 * Devuelve una referencia que indica si el componete está montado o no.
 */
export function useMountRef(): MountRef {
  const mountRef = useRef({ isMounted: true });

  useEffect(() => () => {
    mountRef.current.isMounted = false;
  }, []);

  return mountRef;
}
