import React, { useCallback } from "react";

import Box from "@material-ui/core/Box";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PowerOffIcon from "@material-ui/icons/PowerSettingsNew";

import { useAccessBoundary } from "./AccessBoundary";

/**
 * Menú de usuario.
 */
const UserMenu: React.FC = () => {

  const { authUser, logout } = useAccessBoundary();

  const logoutHandler = useCallback(() => {
    if (logout) {
      logout();
    }
  }, [logout]);

  if (authUser) {
    return (
      <>
        <Box display="flex" alignItems="center">
          <AccountCircle color="primary" />
          <Box ml={0.5}>{authUser.name}</Box>
        </Box>
        <Box ml={1.0} display="flex" alignItems="center" onClick={logoutHandler} style={{ cursor: "pointer" }}>
          <PowerOffIcon color="primary" />
          <Box ml={0.5}>Desconectar</Box>
        </Box>
      </>
    );
  } else {
    return null;
  }
};

export default UserMenu;
