import { lazy } from "react";

import { RouteProps } from "react-router-dom";

const InvoicesView = lazy(() => import("./views/Invoice/List"));
const NewInvoiceView = lazy(() => import("./views/Invoice/New"));
const NewProformaInvoiceView = lazy(() => import("./views/ProformaInvoice/New"));

/**
 * Definición de rutas de la aplicación.
 */
export interface AppRoute {
  component: Exclude<RouteProps["component"], undefined>;
  label: string;
  path: Exclude<RouteProps["path"], undefined>;
}

/** Rutas de la aplicación. */
const routes: AppRoute[] = [
  {
    component: NewInvoiceView,
    label: "Factura Oficial",
    path: ["/invoices/new", "/"],
  },
  {
    component: InvoicesView,
    label: "Consulta facturas",
    path: "/invoices/",
  },
  {
    component: NewProformaInvoiceView,
    label: "Proforma para pagos",
    path: "/proforma-invoices/new",
  },
];

export default routes;
