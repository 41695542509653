import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import logoImagePath from "./logo.png";

/**
 * Estilos por defecto
 */
const styles = (theme: Theme) => createStyles({
  image: {
    height: 24,
  },
  root: {
    display: "inline-block",
    height: 24,
    margin: theme.spacing(0, 2),
  },
});
const useStyles = makeStyles(styles, { name: "AppLogo" });

/**
 * Logo de la aplicación.
 */
const Logo: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        alt="Logotipo Viajes InterRías"
        className={classes.image}
        src={logoImagePath}
      />
    </div>
  );
};

export default Logo;
