import client from "./client";
import { Company, Profile } from "./types";

/**
 * Recupera los datos del usuario
 */
export let get = async (): Promise<Profile> =>
  (await client.get("/profile")).data;

/**
 * Recupera los datos de la empresa del usuario
 */
export let getCompany = async (): Promise<Company> =>
  (await client.get("/profile/company")).data;

/*
 * < MOCKS - Apaño cutre>
 */
if (process.env.REACT_APP_WEBAPI_MOCK === "true") {

  get = async (): Promise<Profile> => {
    await new Promise(resolve => setTimeout(resolve, (Math.random() + 1) * 1500));

    return require("./__mocks__/profiles.data.ts").get();
  };

  getCompany = async (): Promise<Company> => {
    await new Promise(resolve => setTimeout(resolve, (Math.random() + 1) * 1500));

    return require("./__mocks__/companies.data.ts").get();
  };

}
/*
 * </ MOCKS >
 */
