import React from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/SentimentVeryDissatisfiedRounded";

import Dialog from "./Dialog";

/**
 * Estilos por defecto
 */
const styles = (theme: Theme) => createStyles({
  button: {
    "&:active, &:hover": {
      color: theme.palette.error.dark,
    },
    color: theme.palette.error.main,
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 3, 2),
  },
  header: {
    alignItems: "center",
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 3, 2),
  },
  paper: {
    borderRadius: 0,
    minWidth: 280,
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  title: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  titleIcon: {
    fontSize: "6rem",
    marginBottom: theme.spacing(2),
  },
});
const useStyles = makeStyles(styles, { name: "UiErrorAlert" });

/**
 * Propiedades del componente.
 */
export interface ErrorAlertProps {
  /** Texto descriptivo del error. */
  message?: string;

  /** Indica si el elemento está visible. */
  open?: boolean;

  /** Título del error. */
  title?: string;
}

export interface ErrorAlertProps {

  /** Función a ejecutar cuando el usuario cierra el modal. */
  onClose?: () => void;
}

/**
 * Alerta para mostra run error.
 */
const ErrorAlert: React.FC<ErrorAlertProps> = props => {

  const classes = useStyles();

  const { message, title, onClose, open = true } = props;

  return (
    <Dialog open={open} PaperProps={{ className: classes.paper }}>
      <div className={classes.header}>
        <ErrorIcon className={classes.titleIcon} />
        <div className={classes.title}>
          {title}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.text}>
          {message}
          {props.children}
        </div>
        {onClose && (
          <Button variant="text" className={classes.button} onClick={onClose}>
            Cerrar
          </Button>
        )}
      </div>
    </Dialog>
  );

};

export default ErrorAlert;
