import { Company } from "../types";

import {
  randomAddress,
  randomString,
} from "./mockUtils";

export const get = (): Company => ({
  address: randomAddress(),
  bankAccount: {
    bankAccountCode: "0000-0000-00-0000000000",
    bankingEntity: "BBVA",
    email: randomString() + "@gmail.com",
    providerAccountCode: randomString(),
    swift: "BBVAESMM000",
  },
  country: "España",
  documentNumber: "X00000000X",
  fiscalName: randomString(),
  name: randomString(),
  postalCode: "99009",
  province: "Madrid",
  town: "Madrid",
});
