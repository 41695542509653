import React, { useContext } from "react";

import ErrorAlert from "../ui/ErrorAlert";

/** Definición de error */
export interface AppError {
  /** Acción a ejecutar después de que el usuario cierre la notificación del error. */
  handler?: (reason: any) => void;

  /** Texto descriptivo del error. */
  message?: string;

  /** Error original */
  reason?: any;

  /** Título a mostrar. */
  title?: string;
}

/** Contexto de ErrorBoundary */
export interface ErrorBoundaryContext {
  /** Notifica de un error controlado. */
  setError: (error: AppError) => void;
}

/** Instancia del contexto */
const ErrorBoundaryContextInstance = React.createContext<ErrorBoundaryContext>({
  setError: () => null,
});

/** Hook para dar acceso al contexto. */
export const useErrorBoundary = () => useContext(ErrorBoundaryContextInstance);

/** Definición del estado. */
export interface ErrorBoundaryState {
  /** Fallo notificado por algún componente hijo. */
  appError?: AppError | null;

  /** La instancia del contexto que provee. */
  context: ErrorBoundaryContext;

  /** Error no controlado del renderizado de la ui. */
  uiError?: any;
}

/**
 * Componente para la gestión de errores.
 * Caputra los errores no controlados de ejecución y permite que los componentes
 * hijos notifiquen de fallos controlados.
 */
class ErrorBoundary extends React.PureComponent<{}, ErrorBoundaryState> {

  /**
   * Actualiza el estado cuando llega a este nivel un error de renderizado.
   */
  public static getDerivedStateFromError(error: any) {
    return { failure: null, uiError: error };
  }

  /**
   * #constructor
   */
  public constructor(props: {}) {
    super(props);

    this.state = {
      context: {
        setError: appError => {
          this.logError(appError.reason);
          this.setState({ appError });
        },
      },
    };
  }

  /**
   * Registra el error producido en el logger.
   */
  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.logError(error);
    this.logError(info.componentStack);
  }

  /**
   * #render
   */
  public render() {
    const { appError, context, uiError } = this.state;

    if (uiError != null) {
      /* No deberían suceder nunca si está todo bien programado, pero nunca se
       * sabe...
       * Los errores UI son malos malos, no hay nada que hacer más que recargar
       * de nuevo. */
      return (
        <ErrorAlert title="Error" onClose={this.handleUiError}>
          Se ha producido un error durante la ejecución
        </ErrorAlert>
      );

    } else {

      return (
        <ErrorBoundaryContextInstance.Provider value={context}>
          {this.props.children}
          {appError && (
            <ErrorAlert
              title={appError.title || "Error"}
              message={appError.message || "Se ha producido un error al procesar la petición"}
              onClose={this.handleAppError}
            />
          )}
        </ErrorBoundaryContextInstance.Provider>
      );

    }
  }

  /**
   * Handler a ejecutar después de notificar de un error controlado de la
   * aplicación.
   */
  private handleAppError = () => {
    const { appError } = this.state;

    if (appError && appError.handler) {
      appError.handler(appError.reason);
    }

    this.setState({ appError: null, uiError: null });
  }

  /** Handler a ejecutar después de notificar de un error de interfaz */
  private handleUiError = () => {
    window.location.reload();
  }

  /** Saca el error por el log */
  private logError = (error: any) => {
    // tslint:disable-next-line: no-console
    console.log(error);
  }

}

export default ErrorBoundary;
