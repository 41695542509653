import axios from "axios";

import * as Config from "./config";

import { ApiError, AuthTokenHeader, ConnectionTimeout, Failure, HostUnreachable } from "./types";

/** Instacia de axios para las conexiones con webapi. */
const axiosClient = axios.create({
  baseURL: Config.BASE_URI,
  timeout: Config.CONNECTION_TIMEOUT,
});

/** Token de autenticación. */
let authToken: string | undefined;

export const AUTH_TOKEN_SESSION = "authorization-token";

/** Listener de peticiones */
export type RequestTracker = (status: "START" | "END") => void;

/** Funciones a notificar cada vez que se inicia o finaliza una petición. */
const requestTrackers: Set<RequestTracker> = new Set();

/* Interceptor para añadir el token de autenticación a todas las peticiones.
 * Se añade siempre y cuando no exista. */
axiosClient.interceptors.request.use(config => {

  const {
    auth,
    headers,
  } = config;

  /* Se añade el token de autenticación a la petición si:
   * - la petición no trae credenciales (auth)
   * - la petición no trae ya un token de autenticación
   * - tenemos un token de autenticación
   */
  const authTokenValue = authToken ? authToken : sessionStorage.getItem(AUTH_TOKEN_SESSION);

  if (auth == null && headers != null && headers.Authorization == null && authTokenValue) {
    headers.Authorization = `Bearer ${authTokenValue}`;
  }

  return config;
});

/* Interceptor para notificar cuando se inicia una petición. */
axiosClient.interceptors.request.use(config => {
  requestTrackers.forEach(requestTracker => requestTracker("START"));

  return config;
});

/* Interceptor para notificar cuando finaliza una petición. */
axiosClient.interceptors.response.use(
  response => {
    requestTrackers.forEach(requestTracker => requestTracker("END"));
    const { status } = response;

    /* Si es una respuesta OK, entonces actualizamos el token para enviarlo en
     * la siguiente petición. */
    if (status >= 200 && status < 300) {
      const newToken = response.headers ? response.headers[AuthTokenHeader] : null;
      if (newToken != null) {
        authToken = newToken;
        setStorageAuthorizationToken(newToken);
      }
    }

    return response;
  }, error => {
    requestTrackers.forEach(requestTracker => requestTracker("END"));

    throw error;
  });

/** Interceptor para encapsular los errores Http en una estructura conocida. */
axiosClient.interceptors.response.use(
  response => response,
  async error => {
    /* Tratamos los errores conocidos que la app puede gestionar. Si se da algún
     * otro error, se devuelve tal cual. */
    if (error.response) {

      const apiError: Failure = {
        details: error.response.data != null ? { ...error.response.data } : undefined,
        status: error.response.status,
        type: ApiError,
      };

      return Promise.reject(apiError);

    } else if (error.code === "ECONNABORTED") {
      /* Según la doc de Axios se nos devuelve "ECONNABORTED" si se da timeout. */
      return Promise.reject(ConnectionTimeout);

    } else if (error.config != null && error.request != null
      && error.response == null && error.code == null) {
      /* Se mira que hay config y request, para intentar segurar que el error es
       * de una petición axios. Y miramos que no hay ni respuesta ni código, para
       * _suponer_ que es un error de conexión con el servidor. Puede ser que no
       * tengamos red o que el servidor no sea accesible por cualquier otro motivo.
       * La cuestión es que no llegamos. */

      // TODO: Encontrar una condición que asegure que capturamos el error correcto:
      /* La condición del if es en base a varias pruebas, no estoy seguro que no
       * se escape algún caso. */

      // TODO: Ver cómo se podría probar que hay conexión con una petición anterior.

      return Promise.reject(HostUnreachable);
    }

    return Promise.reject(error);
  });

/**
 * Cliente para las peticiones a webapi.
 */
export default axiosClient;

/**
 * Añade una función para controlar el incio y fin de peticiones en curso.
 *
 * @param requestTracker función a registrar
 */
export const addRequestTracker = (requestTracker: RequestTracker) => {
  requestTrackers.add(requestTracker);
};

/**
 * Elimina una función registrada anteriormente.
 *
 * @param requestTracker referencia a la función a eliminar
 */
export const removeRequestTracker = (requestTracker: RequestTracker) => {
  requestTrackers.delete(requestTracker);
};

/**
 * Establece el valor del token de autenticación
 *
 * @param newAuthToken Valor del token de autenticación
 */
export const removeAuthorizationToken = () => {
  authToken = undefined;
  sessionStorage.removeItem(AUTH_TOKEN_SESSION);
};

/**
 * Establece el valor del token de autenticación
 *
 * @param newAuthToken Valor del token de autenticación
 */
export const setStorageAuthorizationToken = (newAuthToken: string) => {
  sessionStorage.setItem(AUTH_TOKEN_SESSION, newAuthToken);
};
