import React from "react";

import { Link } from "react-router-dom";

import Panel from "../components/ui/Panel";

/**
 * Pantalla a mostrar si se introduce una url inválida
 */
const NotFoundView: React.FC = () => {
  return (
    <Panel title="Página no encontrada" variant="main">
      No se ha encontrado la página solicitada:<br />
      <Link to="/">Ir a la página de inicio </Link>
    </Panel>
  );
};

export default NotFoundView;
