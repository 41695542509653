import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import createPalette from "@material-ui/core/styles/createPalette";

/**
 * Configuración del tema para Material-UI
 */
const palette = createPalette({
  background: { default: "#f0f0f0" },
  primary: { main: "#0076c0" },
  secondary: { main: "#489c3d" },
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        fontSize: "inherit",
      },
    },
    MuiTable: {
      root: {},
    },
    MuiTableCell: {
      head: {
        color: palette.primary.main,
      },
    },
    MuiTableHead: {
      root: {},
    },
    MuiTableRow: {
      root: {
        "&.Mui-selected": {
          backgroundColor: fade(palette.secondary.light, 0.076),
        },
      },
    },
  },
  palette,
  typography: {
    body1: { fontSize: "0.875rem" },
    caption: { fontSize: "0.875rem" },
  },
});

export default theme;
