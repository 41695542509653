import React, { Suspense, useMemo } from "react";

import { BrowserRouter as Router, Route, RouteProps, Switch } from "react-router-dom";

import LuxonUtils from "@date-io/luxon";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";

import theme from "./muiTheme";
import routes from "./routes";

import AccessBoundary from "./components/app/AccessBoundary";
import ErrorBoundary from "./components/app/ErrorBoundary";
import MenuBar from "./components/app/MenuBar";
import LoadingOverlay from "./components/ui/LoadingOverlay";
import WaitingLayer from "./components/ui/WaitingLayer";

import NotFoundView from "./views/NotFound";

const routeDefaults: Partial<RouteProps> = {
  exact: true,
  sensitive: true,
  strict: true,
};

/**
 * Nodo raíz de la aplicación
 */
const App: React.FC = () => {

  const loadingOverlay = useMemo(() => <LoadingOverlay visible={true} />, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale="es">
        <CssBaseline>
          <Typography component="div">
            <Router>
              <ErrorBoundary>
                <AccessBoundary>
                  <MenuBar routes={routes} />
                  <Box p={1} pt={2}>
                    <Suspense fallback={loadingOverlay}>
                      <Switch>
                        {routes && routes.map((route, i) => <Route key={i} {...routeDefaults} {...route} />)}
                        <Route {...routeDefaults} component={NotFoundView} />
                      </Switch>
                    </Suspense>
                  </Box>
                </AccessBoundary>
                <WaitingLayer />
              </ErrorBoundary>
            </Router>
          </Typography>
        </CssBaseline>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
