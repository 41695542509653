import React from "react";

import Box, { BoxProps } from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Title, { Props as TitleProps } from "./Title";

/**
 * Estilos del componente.
 */
const useStyles = makeStyles(theme => createStyles({
  content: {
    padding: theme.spacing(2, 2),
  },
  header: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    marginBottom: 0,
    padding: theme.spacing(1, 2),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
}), { name: "Panel" });

/**
 * Popiedades del componente
 */
export interface Props extends BoxProps {
  /** Título del panel */
  title?: string;

  /** Variante del título */
  titleVariant?: TitleProps["variant"];

  /** Variante del panel */
  variant?:
  | "main"
  | "default";

}

/**
 * Panel para agrupar elementos.
 */
const Panel: React.FC<Props> = props => {
  const classes = useStyles();

  const { children, title, titleVariant: propsTitleVariant, variant, ...boxProps } = props;

  const shadow = variant === "main" ? 1 : 0;
  const titleVariant = propsTitleVariant || variant === "main" ? "primary" : undefined;

  return (
    <Box className={classes.root} boxShadow={shadow} {...boxProps}>
      {title && (
        <Title className={classes.header} variant={titleVariant}>{title}</Title>
      )}
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

export default Panel;
