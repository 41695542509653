import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";

/**
 * Propiedades
 */
export interface Props {
  /** Indica si la coritnilla está visible u oculta. */
  visible: boolean;
}

/**
 * Estilos por defecto
 */
const useStyles = makeStyles(
  theme => createStyles({
    backdrop: {
      backgroundColor: theme.palette.grey[100],
      height: "100%",
      left: 0,
      opacity: 0.68,
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 10000,
    },
    content: {
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    progress: {},
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      top: 0,
      width: "100%",
      // TODO: Llevar a theme
      zIndex: 10000,
    },
    text: {
      display: "block",
      minWidth: 100,
    },
  }), {
    name: "AppLoadingOverlay",
  });

/**
 * Componente cortinilla "cargando"
 */
const LoadingOverlay: React.FC<Props> = ({ visible }) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <div className="AppLoadingOverlay">
      <div className={classes.backdrop} />
      <div className={classes.root}>
        <CircularProgress
          className={classes.progress}
          thickness={2}
          size={160}
        />
      </div>
    </div>
  );

};

export default LoadingOverlay;
