import React from "react";

import MaterialDialog, { DialogProps } from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

/**
 * Wrapper sobre el Dialog de MaterialUI.
 * Inclue Typography por defecto.
 */
const Dialog: React.FC<DialogProps> = props => {

  const { children, ...other } = props;

  return (
    <MaterialDialog {...other}>
      <Typography component="div">
        {children}
      </Typography>
    </MaterialDialog>
  );

};

export default Dialog;
