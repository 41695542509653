import React, { useMemo } from "react";

import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { AppRoute } from "../../routes";
import firstOf from "../../utils/firstOf";
import Logo from "./Logo";
import UserMenu from "./UserMenu";

/**
 * Estilos por defecto
 */
const useStyles = makeStyles(
  theme => createStyles({
    root: {
      alignItems: "center",
      flexDirection: "row",
      padding: theme.spacing(0, 1, 0, 0),
    },
  }), {
    name: "AppMenuBar",
  });

/**
 * Propiedades del componente.
 */
export interface Props {
  /* Definición las rutas (pestañas) de la app. */
  routes: Array<Pick<AppRoute, "label" | "path">>;
}

/**
 * Menú de la aplicación.
 */
const MenuBar: React.FC<Props> = ({ routes }) => {

  const { location: { pathname } } = useReactRouter();
  const classes = useStyles();

  /* Si no hay match con ninguna ruta, entonces el valor debe ser "false" para
   * indicar que no hay ninguna seleccionada. */
  const selectedTab = useMemo(() => {
    // TODO: Array.includes y .find necesitará polyfill para IE
    const currentRoute = routes.find(({ path }) =>
      Array.isArray(path) ? path.includes(pathname) : path === pathname);

    return currentRoute ? firstOf(currentRoute.path) : false;
  }, [routes, pathname]);

  return (
    <AppBar position="static" className={classes.root} color="inherit">
      <Logo />
      <Tabs value={selectedTab}>
        {routes.map(({ label, path }, index) => {
          const mainPath = firstOf(path);

          return (mainPath != null)
            ? <Tab key={index} label={label} value={mainPath} to={mainPath} component={Link} />
            : null;
        })}
      </Tabs>
      <Box flexGrow={1} />
      <UserMenu />
    </AppBar>
  );
};

export default MenuBar;
